import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  NavigationV2,
  SocialMedia,
  RecaptchaProvider,
  LocationSelect,
  HookComponentProvider,
  Phone,
  LocationsContext,
  Email,
  HoursOfOperation,
  Map,
  OutboundLink,
  Location,
  EmailCapture,
  SiteMap,
  CopyrightLabel,
  FishermanBanner,
} from "@bluefin/components";
import { Header, Grid, Button } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import { getComponentFiles } from "../utils/utils";
import "semantic-ui-less/semantic.less";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          socialMedia {
            link
            type
          }
          _id
          type
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        bookingUrl: fishermanBusinessWebsitePage(
          title: { regex: "/Book Now|Make An Appointment/" }
          pageType: { eq: "External" }
        ) {
          url
          title
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        bookingUrl,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://tattoosbyfisherman.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://tattoosbyfisherman.com"}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <NavigationV2
                sticky={true}
                fullScreen={true}
                desktop={
                  <React.Fragment>
                    <NavigationV2.Container className={"links"} vertical={true}>
                      <NavigationV2.Logo
                        title={<Header>{fishermanBusiness.name}</Header>}
                      />
                      <NavigationV2.Item className={"links"}>
                        <NavigationV2.Links
                          links={allFishermanBusinessWebsitePage.nodes}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Container>
                    <NavigationV2.Container
                      className={"social-media"}
                      vertical={true}
                    >
                      <SocialMedia
                        socialMediaValues={fishermanBusiness.socialMedia}
                        buttonType={"rect"}
                        groupButtons={false}
                        showLabels={false}
                      />
                    </NavigationV2.Container>
                  </React.Fragment>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"menu-header"}>
                      <NavigationV2.Toggle />
                    </NavigationV2.Container>
                    <NavigationV2.Container
                      tray={true}
                      vertical={true}
                      className={"menu-content"}
                    >
                      <NavigationV2.Menu>
                        <NavigationV2.Toggle content={"CLOSE MENU"} />
                        <NavigationV2.Item className={"links"}>
                          <NavigationV2.Links
                            links={allFishermanBusinessWebsitePage.nodes}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
            </React.Fragment>
            <RecaptchaProvider>
              <div className={"top-right-business-information"}>
                <div className={"location-information"}>
                  <LocationSelect useContext={true} search={false} />
                  <HookComponentProvider
                    hook={useContext}
                    component={Phone}
                    hookToPropsMap={{ phone: "activeLocation.phoneNumber" }}
                    hookParams={[LocationsContext]}
                    onlyRenderOnClientSide={true}
                  />
                  <HookComponentProvider
                    hook={useContext}
                    component={Email}
                    hookToPropsMap={{ email: "activeLocation.email" }}
                    hookParams={[LocationsContext]}
                    onlyRenderOnClientSide={true}
                  />
                  <HookComponentProvider
                    hook={useContext}
                    component={
                      <HoursOfOperation
                        displayOption={"dailyWithPopup"}
                        displayOpenClosedStatus={true}
                        startWithToday={false}
                        position={"top left"}
                      />
                    }
                    hookToPropsMap={{
                      hours: "activeLocation.hours",
                      timezone: "activeLocation.timezone",
                    }}
                    hookParams={[LocationsContext]}
                    onlyRenderOnClientSide={true}
                  />
                </div>
                <SocialMedia
                  socialMediaValues={fishermanBusiness.socialMedia}
                  buttonType={"rect"}
                  groupButtons={false}
                  showLabels={false}
                />
              </div>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Grid stackable={true}>
                  <Grid.Column width={7} className={"map-column"}>
                    <HookComponentProvider
                      hook={useContext}
                      component={Map}
                      hookToPropsMap={{
                        "address.street": "activeLocation.street",
                        "address.city": "activeLocation.city",
                        "address.state": "activeLocation.state",
                        "address.zip": "activeLocation.zipCode",
                      }}
                      hookParams={[LocationsContext]}
                      onlyRenderOnClientSide={true}
                    />
                  </Grid.Column>
                  <Grid.Column width={9} className={"content-column"}>
                    <Grid stackable={true}>
                      <Grid.Row columns={"equal"}>
                        <Grid.Column textAlign={"left"}>
                          <LocationSelect useContext={true} search={false} />
                        </Grid.Column>
                        <Grid.Column textAlign={"right"}>
                          <Button as={OutboundLink} to={bookingUrl.url}>
                            {bookingUrl.title}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        columns={"equal"}
                        className={"footer-contact-info"}
                      >
                        <Grid.Column>
                          <HookComponentProvider
                            hook={useContext}
                            component={<Location businessName={"Address"} />}
                            hookToPropsMap={{
                              "address.street": "activeLocation.street",
                              "address.city": "activeLocation.city",
                              "address.state": "activeLocation.state",
                              "address.zip": "activeLocation.zipCode",
                            }}
                            requiredProps={[
                              [
                                "address.street",
                                "address.city",
                                "address.state",
                                "address.zipCode",
                              ],
                            ]}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <div>
                                <strong>Contact</strong>
                              </div>
                            }
                            hookToPropsMap={{
                              phone: "activeLocation.phoneNumber",
                              email: "activeLocation.email",
                            }}
                            requiredProps={[["phone", "email"]]}
                            hookParams={[LocationsContext]}
                          />
                          <HookComponentProvider
                            hook={useContext}
                            component={Phone}
                            hookToPropsMap={{
                              phone: "activeLocation.phoneNumber",
                            }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                          <HookComponentProvider
                            hook={useContext}
                            component={Email}
                            hookToPropsMap={{ email: "activeLocation.email" }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <HoursOfOperation
                                displayOption={"dailyWithPopup"}
                                displayOpenClosedStatus={true}
                                startWithToday={false}
                                position={"top left"}
                                header={<strong>Hours of Operation</strong>}
                              />
                            }
                            hookToPropsMap={{
                              hours: "activeLocation.hours",
                              timezone: "activeLocation.timezone",
                            }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <EmailCapture
                              businessId={fishermanBusiness._id}
                              businessType={fishermanBusiness.type}
                              type={"footer"}
                            />
                          }
                          hookToPropsMap={{ locationId: "activeLocation.id" }}
                          hookParams={[LocationsContext]}
                        />
                        <SiteMap
                          links={allFishermanBusinessWebsitePage.nodes}
                          horizontal={true}
                          bulleted={false}
                        />
                        <CopyrightLabel
                          company={fishermanBusiness.name}
                          phrase={"All Rights Reserved"}
                          privacyPolicyLink={
                            <OutboundLink
                              to={
                                "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                              }
                              label={"Privacy Policy"}
                              eventLabel={"copyright-privacy-policy"}
                            />
                          }
                        />
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </footer>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
